<template>
	<div class="transaction">
		<div class="header">
			<input
				type="checkbox"
				@change.stop="event => handleAddToExportList(event, transaction)"
				@click.stop
				:checked="store.transactionExportList.has(transaction)"
			/>
			<div class="title">
				<h3 class="address">
					{{ transaction.location.address || $t("unknownAddress") }}
				</h3>
				<div class="borough">
					{{ transaction.location?.borough }}
				</div>
			</div>
		</div>
		<div class="separator"></div>

		<div class="items">
			<h1 class="price">
				<span>
					<span class="currency">$</span>
					<span class="amount">{{ (transaction.deal?.price || "").toLocaleString() }}</span>
				</span>
			</h1>

			<div class="details">
				<h4 class="intro">
					{{ $t("listings.details") }}
				</h4>

				<div class="data">
					<div class="xfield">
						<span class="label">{{ $t("transaction.parcels") }}</span>
						<span class="value">{{ transaction.location.parcelNumbers.join(", ") }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.area") }}</span>
						<span class="value">{{ transaction.location.area }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.registryDate") }}</span>
						<span class="value">{{ transaction.deal?.registryDate?.toLocaleDateString("en-CA") }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.sellerName") }}</span>
						<span class="value">{{ transaction.deal.seller.name }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.sellerNeq") }}</span>
						<span class="value">{{ transaction.deal.seller.neq }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.buyerName") }}</span>
						<span class="value">{{ transaction.deal.buyer.name }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.buyerNeq") }}</span>
						<span class="value">{{ transaction.deal.buyer.neq }}</span>
					</div>

					<div class="xfield">
						<span class="label">{{ $t("transaction.registrationNumber") }}</span>
						<span class="value">{{ transaction.registrationNumber }}</span>
					</div>
				</div>

				<div class="field" :class="{ more: !transaction.data?.actual.land }" :key="landDev">
					<span class="data-name">Land Development</span>

					<div class="data-sources">
						<div class="data-source external-data">
							<!--
							<label for="land-development">🌐</label>
							-->
							<label for="land-development">Externe</label>
							<span
								id="land-development"
								class="source-value"
								:data-value="transaction.data?.cmsd.land"
								@click="handleDataSourceSelection"
							>
								{{ LandDevelopment.get(transaction.data?.cmsd.land) }}
							</span>
						</div>

						<div class="data-source internal-data">
							<!--
							<label for="land-development">📜</label>
							-->
							<label for="land-development">Interne</label>
							<span
								id="land-development"
								class="source-value"
								:data-value="transaction.data?.landerz.land"
								@click="handleDataSourceSelection"
							>
								{{ LandDevelopment.get(transaction.data?.landerz.land) }}
							</span>
						</div>

						<div class="data-source override">
							<label for="land-development">Actuelle</label>
							<span id="land-development" class="source-value">
								<select v-model="landDev">
									<option v-for="[value, text] of LandDevelopment" :value="value">
										{{ text }}
									</option>
								</select>
							</span>
						</div>
					</div>

					<span class="data-value">
						{{ LandDevelopment.get(transaction.data?.actual.land) }}
					</span>

					<div class="toggle-sources" @click="event => event.target.closest('.field').classList.toggle('more')"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from "vue"
import { UseRootStore } from "@/model/RootStore"

const props = defineProps({
	transaction: {
		type: Object,
		require: true,
	},
})

// prettier-ignore
const LandDevelopment = new Map([
//  [value,		      text]
	["land",         "Oui"],
	["notLand",      "Non"],
	["undetermined", "¯\\_(ツ)_/¯"],
	[null,           ""],
]);

const landDev = ref(null)

{
	function normalizeId(id) {
		return parseInt(String(id).replace(/\s/g, ""))
	}
	function coordToNum(coord) {
		return parseInt(String(coord).replace(/\./, ""))
	}
	function landDevFor(v) {
		const values = [...LandDevelopment.keys()]
		return values[v % values.length]
	}

	watch(
		() => props.transaction,
		tr => {
			if (tr.data) {
				if (!landDev.value) {
					landDev.value = tr.data.actual.land
				}
				return
			}
			tr.data = {
				cmsd: {
					land: landDevFor(normalizeId(tr.id)),
				},
				landerz: {
					land: landDevFor(coordToNum(tr?.location?.coordinates?.latitude)),
				},
				actual: {
					land: null,
				},
			}
			landDev.value = null
		},
		{ immediate: true, deep: true }
	)

	watch(landDev, value => {
		props.transaction.data.actual.land = value || null
	})
}

function handleDataSourceSelection(event) {
	const value = event.target.dataset.value
	landDev.value = value
}

const store = UseRootStore()

function handleAddToExportList(event, transaction) {
	const box = event.target

	// prettier-ignore
	box.checked ?
		store.transactionExportList.add(transaction) :
		store.transactionExportList.delete(transaction)
}
</script>

<style scoped>
@import "@/assets/styles/variables.scss";

.transaction {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1.5rem;
	padding: 1.4rem 1.4rem 1.8rem 1.4rem;
	overflow: auto;
	background-color: white;

	& > .header {
		display: flex;
		flex-direction: row;
		gap: 0.4rem;
		min-height: 2.5em;

		input[type="checkbox"] {
			transform: scale(2);
			width: 10px;
			margin-left: 5px;
		}
		.title {
			margin: 0 40px 0 10px;
		}
	}
	.address {
		color: #000;
		font-family: "basier_circlemedium", sans-serif;
		font-size: 2.3rem;
		font-weight: 500;
		line-height: 2.53rem;
	}
	.borough {
		color: #777;
		font-family: "basier_circleregular", sans-serif;
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 1.8rem;
	}
	.separator {
		width: 100%;
		height: 0.15rem;
		background-color: #c4c4c4;
	}
	.items {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 2.4rem;

		.price {
			font-family: "basier_circlesemibold", sans-serif;
			font-size: 3.3rem;
			font-weight: 600;
			line-height: 3.63rem;

			.currency {
				color: black;
				opacity: 0.7;
			}
			.amount {
				color: var(--accent-color);
			}
		}
		.details {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 1rem;

			.intro {
				color: #191716;
				font-family: "basier_circlesemibold", sans-serif;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 2.24rem;
			}
			.data {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 0.6rem;
			}
			.xfield {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #777;
				padding: 5px 0;
				&:last-child {
					border-bottom: none;
				}
				.label {
					color: #191716;
					font-family: "basier_circleregular", sans-serif;
					font-size: 1.4rem;
					font-weight: 400;
					line-height: 1.866rem;
					text-transform: capitalize;
				}
				.value {
					color: #191716;
					font-family: "basier_circlemedium", sans-serif;
					font-size: 1.4rem;
					font-weight: 500;
					line-height: 1.866rem;
					&:empty::after {
						content: "-";
					}
				}
			}
			.field {
				font-family: "basier_circlesemibold", sans-serif;
				font-size: 1.4rem;
				font-weight: 400;

				display: flex;
				justify-content: space-between;
				gap: 10px;

				padding: 15px 0;

				/* TEMP */
				border-top: 1px solid #777;

				.data-sources {
					flex: 1;

					display: flex;
					flex-direction: column;
					gap: 5px;

					.data-source {
						display: flex;
						justify-content: space-between;
						gap: 10px;

						label {
							&::after {
								content: ":";
							}
						}

						.source-value {
							cursor: pointer;
						}

						&.internal-data .source-value {
							color: var(--accent-color);
						}
						&.override {
							select {
								padding: 5px;
							}
						}
					}
				}
				&:not(.more) .data-sources {
					display: none;
				}

				.toggle-sources {
					flex: 0 1 content;
					display: flex;
					align-items: start;
					padding: 0 10px;
					cursor: pointer;
					user-select: none;
					font-size: 1.2em;
				}
				&.more .toggle-sources {
					&::after {
						content: "▾";
						position: relative;
						top: 0.1em;
						left: -0.1em;
					}
				}
				&:not(.more) .toggle-sources {
					&::after {
						content: "▸";
					}
				}

				.data-value {
					flex: 1;

					display: flex;
					justify-content: flex-end;
				}
				&.more .data-value {
					display: none;
				}
			}
		}
	}
}
</style>
